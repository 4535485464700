import React from 'react'

import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import MH from '../components/MH/MH'
import Contact from '../components/contact/ContactForm'
import Menus from '../components/menus/Menus';



const MHealth = () => {
  return (
    <>
    <Navbar /> 
    <MH />
    <Menus />
    <Contact />
    <Footer />
     </>
  )
}

export default MHealth