import React from 'react'
import './AboutSection.css'
import ContactForm from '../contact/ContactForm'

import Mission from '../../assets/mission-img1.png'



const AboutSection = () => {
    return (
        <>


            <div className='aboutSection container'>





                        <div className='aboutSectionContent '>


                            <div className='about-section-flex-left'>
                                <div className='aboutSectionCaption'>
                                    <h1> WHO WE ARE </h1>
                                    <p>We seek to provide the highest quality care for any addiction situation to create long-term sobriety in a safe and effective environment built on love, acceptance, and tolerance. We work every day to create addict programming that gives you the time and tools needed to live a sober life today, and then vigorously push you to fulfill your dreams tomorrow. </p>
                                </div>
                            
                            <div className='aboutSectionFlex'>
                                <div className='flexLeftItem'>
                                    <h1><span>01. </span>Support <span> Group</span></h1>
                                    <p>Therapy and support groups for either addiction or mental health disorders</p>
                                </div>

                                <div className='flexLeftItem'>
                                    <h1><span>02. </span>Compassionate <span> Staff</span></h1>
                                    <p>Compassionate staff that is dedicated to your success</p>
                                </div>

                                <div className='flexLeftItem'>
                                    <h1><span>03. </span>Proven <span> Method</span></h1>
                                    <p>Evidence-based practices that give you the best chance of recovery</p>
                                </div>

                                <div className='flexLeftItem'>
                                    <h1><span>04. </span>Job <span> Assistance</span></h1>
                                    <p>Employment tools and resources to get you hired.</p>
                                </div>
                            </div>
                        </div>


                        <div className='flexRight'>
                            <div className='aboutSectionRight'>
                                <img src={ Mission } alt="Woman Therapist" loading="lazy"/>
                            </div>
                        </div>


                </div>

            </div>



        <ContactForm />

</>
    )
}

export default AboutSection
