import React from 'react'

import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import SA from '../components/SA/SA'
import Contact from '../components/contact/ContactForm'
import MenusSA from '../components/menusSA/MenusSA';


const SAbuse = () => {
  return (
    <>
    <Navbar /> 
    <SA />
    <MenusSA />
    <Contact />
    <Footer />
     </>
  )
}

export default SAbuse