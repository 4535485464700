import React from 'react'
import Fade from 'react-reveal/Fade';
import {Helmet} from "react-helmet";
import './Kratom.css'

const Kratom = () => {
    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>KRATOM ADDICTION - BAKERSFIELD REHAB</title>
            <link rel="canonical" href="https://bakersfieldrehab.biz/kratom" />
        </Helmet>

        <div className='kratom submenu'>

                <div className="kratom-content">
                    <Fade top>
                    <h1>KRATOM ADDICTION</h1>
                    </Fade>
            </div>
        </div>
        </>
    )
}

export default Kratom
