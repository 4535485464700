import React from 'react'
import Fade from 'react-reveal/Fade';
import {Helmet} from "react-helmet";
import './Mdma.css'

const Mdma = () => {
    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>MDMA ADDICTION - BAKERSFIELD REHAB</title>
            <link rel="canonical" href="https://bakersfieldrehab.biz/mdma" />
        </Helmet>

        <div className='mdma submenu'>

                <div className="mdma-content">
                    <Fade top>
                    <h1>MDMA ADDICTION</h1>
                    </Fade>
            </div>
        </div>
        </>
    )
}

export default Mdma
