import React from "react";
import { Link as LinkRoll } from "react-scroll";
import { Link } from 'react-router-dom';
import Zoom from 'react-reveal/Zoom';
import {Helmet} from "react-helmet";
import "./Hero.css";

const Hero = () => {
  return (
    <>
  <Helmet>
    <meta charSet="utf-8" />
      <title>HOME - BAKERSFIELD REHAB</title>
    <link rel="canonical" href="https://bakersfieldrehab.biz/" />
  </Helmet>


<figure>

	<div class="imagesContainer">

  <div className="hero-caption">
  <Zoom duration={5000}>
    <p>WELCOME TO</p>
    </Zoom>
    <Zoom duration={1000}>
      <h1>BAKERSFIELD</h1>
    </Zoom>
    <Zoom duration={3000}>
    <h1>REHAB</h1>
    </Zoom>

    <Zoom duration={4000}>
    <h2>Restoring Heroes, Rebuilding Lives: A Rehab Haven for Veterans</h2>
    </Zoom>

    <Zoom duration={4000}>
    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
      <Link to="/contact">
            <div className="hero-btn">
              <button>CALL US</button>
            </div>
          </Link>
      </LinkRoll>
      </Zoom>

  </div>


		<div class="imageDiv image1">
    </div>
		<div class="imageDiv image2 fadeInClass"></div>
	</div>
</figure>
    </>
  );
};

export default Hero;
