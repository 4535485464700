import React from "react";
import Fade from 'react-reveal/Fade';
import {Helmet} from "react-helmet";
import './Insurance.css'



const Insurance = () => {

    return (
            <>
              <Helmet>
                <meta charSet="utf-8" />
                <title>INSURANCE - BAKERSFIELD REHAB</title>
                <link rel="canonical" href="https://bakersfieldrehab.biz/insurance" />
            </Helmet>

            <div className='insurance main-menu'>
                        <div className="insurance-content">
                            <Fade top>
                                <h1>INSURANCE</h1>
                            </Fade>
                        </div>
                    </div>



            </>
    )

    }
export default Insurance




