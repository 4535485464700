import React from 'react'
// import {Link} from 'react-router-dom'
import Fade from 'react-reveal/Fade';
import {Helmet} from "react-helmet";
import './About.css'



const About = () => {
    return (

<>
    <Helmet>
        <meta charSet="utf-8" />
        <title>ABOUT US - BAKERSFIELD REHAB</title>
        <link rel="canonical" href="https://bakersfieldrehab.biz/about-us" />
    </Helmet>

        <div className='about'>
                <div className="about-content">
                    <Fade top>
                    <h1>ABOUT US</h1>
                    </Fade>
                </div>
            </div>
</>
    )
}

export default About
