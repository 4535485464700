import React from "react";
import Fade from 'react-reveal/Fade';
import {Helmet} from "react-helmet";
import ContactForm from "./ContactForm";
import './Contact.css'


const Contact = () => {

    return (
      <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>CONTACT US - BAKERSFIELD REHAB</title>
        <link rel="canonical" href="https://bakersfieldrehab.biz/contact" />
        </Helmet>

        <div className='contact-hero main-menu'>
            <div className="contact-hero-content">
            <Fade top>
                    <h1>HAVE SOME QUESTIONS?</h1>
            </Fade>
            <Fade right>
                  <p>Our Addiction and Recovery Support team is available to assist you. </p>
            </Fade>

            <Fade top>
                    <h2>+1 (323) 403-5422</h2>
            </Fade>

            </div>
        </div>

      <ContactForm />

      </>
    )
}

export default Contact