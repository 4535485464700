import React from 'react'
import Fade from 'react-reveal/Fade';
import {Helmet} from "react-helmet";
import './Fentanyl.css'

const Fentanyl = () => {
    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>FENTANYL ADDICTION - BAKERSFIELD REHAB</title>
            <link rel="canonical" href="https://bakersfieldrehab.biz/fentanyl" />
        </Helmet>
        <div className='fentanyl submenu'>
                <div className="fentanyl-content">
                    <Fade top>
                    <h1>FENTANYL ADDICTION</h1>
                    </Fade>
            </div>
        </div>
        </>
    )
}

export default Fentanyl
